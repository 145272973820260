import React, {useEffect, useState} from 'react'
import {css} from '@emotion/core'
import {getTheme, getThemeStyles} from '../util/theme'
import {getLanguageCode} from '../util/locale'
import Layout from '../components/Layout'
import SearchForm from '../components/error/search-form'
import {logDpl, EVENTS} from '../util/dpl'
import HomepageFooter from '../components/HomepageFooter'

import StartpageLogoNew from '../images/startpage-logo-gradient.svg'
import StartpageLogoNewDark from '../images/startpage-logo-gradient-dark.svg'

const notFoundTranslations = require('../translations/notfound.json')

const getLocalizedText = (key, lang = 'en') => notFoundTranslations[lang][key] || notFoundTranslations.en[key];

const NotFound = ({languageCode}) => {
    const lang = languageCode || getLanguageCode()

    const [theme, setTheme] = useState('light')
    const [themeStyles, setThemeStyles] = useState({})

    useEffect(() => {
        setTheme(getTheme())
        setThemeStyles(getThemeStyles())
    }, [theme])

    useEffect(() => {
        logDpl(EVENTS.StartpageNotFoundVisit, {
            url: window.location.href || '',
        })
    }, [])

    return (
        <Layout
            title={getLocalizedText('title', lang)}
            description={getLocalizedText('description', lang)}
            lang={lang}
            canonicalPath="/notfound/"
            theme={theme}
            themeStyles={themeStyles}
            errorHeader={getLocalizedText('headerMessage', lang)}
            noindex
        >
            <section
                className="wrapper"
                css={css`
                    max-width: 100%;
                    padding: 0 1rem;
                `}
            >
                <div
                    className="search-container"
                    css={css`
                        max-width: 500px;
                        margin: 0 auto;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        position: relative;
                    `}
                >
                    <div
                        css={css`
                            width: 240px;
                            margin-top: 115px;
                            @media (max-width: 990px) {
                                width: 192px;
                                margin-top: 4rem;
                                transform: scale(0.9);
                            }
                        `}
                    >
                        {theme === "light" ? (
                                <StartpageLogoNew css={css`
                                    width: 100%;
                                `} />
                            ) : (
                                <StartpageLogoNewDark css={css`
                                    width: 100%;
                                `} />
                            )
                        }
                    </div>
                    <SearchForm theme={theme} themeStyles={themeStyles} languageCode={lang} />
                    <div className="tagline">
                        <p
                            css={css`
                                color: ${themeStyles.linkColor};
                                font-size: 18px;
                                text-align: center;
                                margin: -8px;
                                @media (max-width: 990px) {
                                    font-size: 16px;
                                }
                            `}
                        >
                            {getLocalizedText('tagline', lang)}
                        </p>
                    </div>
                </div>
            </section>
            <HomepageFooter lang={lang} />
        </Layout>
    )
}

export default NotFound

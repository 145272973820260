import {css} from '@emotion/core'
import {getTheme, getThemeStyles} from '../util/theme'
import React, {useState, useEffect} from 'react'

const HomepageFooter = ({lang}) => {
    const [theme, setTheme] = useState('light')
    const [themeStyles, setThemeStyles] = useState({})

    useEffect(() => {
        setTheme(getTheme())
        setThemeStyles(getThemeStyles())
    }, [theme])

    const notFoundTranslations = require('../translations/notfound.json')

    const getLocalizedText = (key, lang = 'en') => notFoundTranslations[lang][key] || notFoundTranslations.en[key];

    return (
        <div
        className="footer"
        css={css`
            position: absolute;
            padding: 0 20px;
            bottom: 0;
            height: 40px;
            width: 100%;
            background: ${themeStyles.darkerBackgroundColor};
            display: flex;
            justify-content: center;
            align-items: center;
        `}
    >
        <div css={css`
            max-width: 500px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            a {
                text-decoration: none;
                font-size: 14px;
                text-align: center;
                width: 150px;
                color: ${themeStyles.footerLinkColor}
            }
        `}>
            <a href={`https://www.startpage.com/${lang}/privacy-policy`} rel="noreferrer noopener nofollow" target="_self" aria-label="link">{getLocalizedText("privacyPolicy", lang)}</a>
            <a href={`https://www.startpage.com/${lang}/about-us`} rel="noreferrer noopener nofollow" target="_self" aria-label="link">{getLocalizedText("aboutUs", lang)}</a>
            <a href="https://www.startpage.com/privacy-please/" rel="noreferrer noopener nofollow" target="_self" aria-label="link">{getLocalizedText("press", lang)}</a>
        </div>
    </div>
    )
}

export default HomepageFooter;
